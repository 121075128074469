.main-content-select::-webkit-scrollbar {
  width: 0.5rem;
  height: 5rem;
}
/* Handle */
.main-content-select::-webkit-scrollbar-thumb {
  background: #0a2a66;
  /* height: 5rem; */
  border-radius: 1rem;
}
.main-content-select::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
}
.ellipsis-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tooltip-unit {
  display: none;
}
.tooltip-unit-container:hover .tooltip-unit {
 display: inline-block;
}
input[type="checkbox"] {
  width: 1.5rem;
  height: 1.5rem;
}

.btn-select-all {
  width: 20.625rem;
  height: 5.625rem;
  background: #e9eef8;
  border-radius: 0.5rem;
  border: none;
  color: #0a2a66;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.375rem;
  cursor: pointer;
  border: 1.5px solid #0a2a66;
}

.btn-select-all:hover {
  background: #fafbfc;
  color: #0a2a66;
  box-shadow: 0rem 0.375rem 1rem rgba(10, 42, 102, 0.25);
}

.btn-select {
  width: 20.625rem;
  height: 5.625rem;
  border-radius: 0.5rem;
  border: none;
  cursor: pointer;
  background-color: #e9eef8;
  border: 1.5px solid #0a2a66;
}

.btn-select:hover {
  background-color: #fafbfc;
  box-shadow: 0rem 0.375rem 1rem rgba(10, 42, 102, 0.25);
}
.select-unit-name span math{
  font-size: 0.9rem !important;
  font-weight: 700 !important;
}
