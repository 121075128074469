input[type="radio"] {
  appearance: none;
  width: 1rem;
  height: 1rem;
  border: 0.15em solid #0A2A66;
  border-radius: 0.125rem;
}

input[type="radio"]:checked {
  background-image: url("/public/images/button/radio.svg");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
}