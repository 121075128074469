.main-content-multiple-vertical::-webkit-scrollbar {
  width: 0.5rem;
  height: 15rem;
}
/* Handle */
.main-content-multiple-vertical::-webkit-scrollbar-thumb {
  background: #0a2a66;
  /* height: 5rem; */
  border-radius: 1rem;
}
.main-content-multiple-vertical::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
}
