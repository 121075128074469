input[type="checkbox"] {
  appearance: none;
  width: 1.5rem;
  height: 1.5rem;
  border: 0.15em solid #0A2A66;
  border-radius: 0.125rem;
}


input[type="checkbox"]:checked {
  background-color:#FAFBFC;
  background-image: url("/public/images/button/check-box.svg");
  background-repeat: no-repeat;
  /* background-size: contain; */
  background-position: center;
}