.question-quiz {
    filter: drop-shadow(0px 6px 16px rgba(10, 42, 102, 0.25));
  }
  
  .question-quiz::-webkit-scrollbar {
    width: 0.5rem;
    height: 5rem;
  }
  /* Handle */
  .question-quiz::-webkit-scrollbar-thumb {
    background: #0a2a66;
    /* height: 5rem; */
    border-radius: 1rem;
  }
  .question-quiz::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
  }
  
  .columns {
    padding: 0;
  }
  
  .modal__quiz {
    padding: 2.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
    border-radius: 2rem;
    position: relative;
  }
  
  .modal__monthly-exam .title__loading {
    font-style: normal;
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 1.375rem;
    color: #0a2a66;
  }
  
  .modal__monthly-exam .loader__quiz {
    height: 0.5rem;
    width: 29.25rem;
    display: inline-block;
    position: relative;
    background: #E9EEF8;
    overflow: hidden;
    border-radius: 1rem;
  }
  
  .modal__monthly-exam .loader__quiz::after {
    content: "";
    box-sizing: border-box;
    width: 0;
    height: 0.5rem;
    background: #0a2a66;
    position: absolute;
    top: 0;
    left: 0;
    animation: animFw 5s linear infinite;
  }
  
  @keyframes animFw {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
  
  .modal__monthly-exam .finished-notice {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    align-items: center;
  }
  
  
  
  .title-notice-monthly {
    font-weight: 700;
    font-size: 2.5rem;
    line-height: normal;
    color: #0a2a66;
  }
  
  .modal__monthly-exam {
    padding: 2.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
    border-radius: 2rem;
  }
  
  .background-monthly-exam {
    /* background-image: url("/public/images/background-monthly-exams.svg"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .finished-notice .back-exam {
    margin-top: 1.5rem;
    color: #0a2a66;
    border-bottom: 1px solid #0a2a66;
    cursor: pointer;
    text-align: center;
    font-size: 1rem;
    line-height: 1.3rem;
  }
  
  .finished-notice .document-monthly-exam {
    text-align: center;
    padding: 1.5rem;
    background: #fafbfc;
    border-radius: 1rem;
    width: 38.626rem;
  }
  
  .finished-notice .document-monthly-exam p {
    color: #0a2a66;
    font-size: 1.25rem;
  }
  
  .finished-notice .group-score-exams {
    text-align: center;
    padding: 1.5rem 2.25rem 4rem;
    width: 12.43rem;
    height: 14.25rem;
    border-radius: 1rem;
    background: #e6e1f5;
  }
  
  .group-score-exams .image-success-exams {
    position: absolute;
    top: 34.5rem;
    left: 1rem;
  }
  
  /* .finished-notice .group-score-exams p {
    font-size: 6rem;
    font-weight: 700;
    text-align: center;
    color: #572CD1;
  } */
  
  .finished-notice .group-score-exams .score span {
    text-align: center;
    color: #572cd1;
    font-size: 2rem;
  }
  
  .back-year-exam {
    margin-top: 1.5rem;
    color: #fafbfc;
    border-bottom: 1px solid #fafbfc;
    cursor: pointer;
    text-align: center;
    font-size: 1rem;
    line-height: 1.3rem;
  }
  
  .finished-notice .image-success-exams img {
    width: 100%;
    height: 100%;
  }
  