.arrows {
  width: 1rem;
  /* display: flex; */
  /* justify-content: center; */
  height: 72px;
  /* align-items: center; */
  /* margin: 0 auto; */
  /* top: 0.5vw; */
  /* left: 3.3vw; */
  margin-left: 1.8rem;
  margin-top: -0.8rem;
  z-index: 1000;
}

.container-arrows .box-arrows {
  position: sticky;
  z-index: 1000;
  top: 40vw;
  /* height: 100%; */
  /* left: 57vw; */
  background-color: #FAFBFC;
  border-radius: 50%;
}

.container-arrows .box-arrows:hover {
  cursor: pointer;
}

.arrows path {
  stroke: #48A9F8;
  /* fill: transparent; */
  stroke-width: 1px;
  animation: arrow 2s infinite;
  -webkit-animation: arrow 2s infinite;
  width: 1.50013rem;
}

@keyframes arrow {
  0% {
    opacity: 1
  }

  40% {
    opacity: 1
  }

  80% {
    opacity: 0
  }

  100% {
    opacity: 0
  }
}

@-webkit-keyframes arrow

/*Safari and Chrome*/
  {
  0% {
    opacity: 1
  }

  40% {
    opacity: 1
  }

  80% {
    opacity: 0
  }

  100% {
    opacity: 0
  }
}

/* @media (min-width: 1440px) {
  .arrows {
    width: 1.50013rem;
    height: 1.5rem;
    position: absolute;
    top: 1vw;
    left: 2.5vw;
  }

  .container-arrows .box-arrows {
    position: sticky;
    z-index: 1000;
    top: 39vw;
    background-color: #FAFBFC;
    border-radius: 50%;
  }

  .container-arrows .box-arrows p {
    font-size: 0.625rem;
    font-weight: 700;
    position: absolute;
    color: #48A9F8;
    text-transform: uppercase;
    top: 2.8vw;
    width: 70%;
    left: 1.3vw;
    z-index: 1000;
  }
}

@media (max-width: 1440px) and (min-width: 1025px) {
  .arrows {
    width: 1.50013rem;
    height: 1.5rem;
    position: absolute;
    top: 2vw;
    left: 4vw;
  }

  .container-arrows .box-arrows {
    position: sticky;
    z-index: 1000;
    top: 39vw;
    background-color: #FAFBFC;
    border-radius: 50%;
  }

  .container-arrows .box-arrows p {
    font-size: 0.625rem;
    font-weight: 700;
    position: absolute;
    color: #48A9F8;
    text-transform: uppercase;
    top: 4.8vw;
    width: 70%;
    left: 2vw;
    z-index: 1000;
  }
} */


.arrows path.a1 {
  animation-delay: -4s;
  -webkit-animation-delay: -4s;
  /* Safari 和 Chrome */
}

/* 
.arrows path.a2 {
  animation-delay: -0.5s;
  -webkit-animation-delay: -0.5s;
  /* Safari 和 Chrome */
/* } */

/* .arrows path.a3 {
  animation-delay: 0s;
  -webkit-animation-delay: 0s;
  /* Safari 和 Chrome */
/* } */