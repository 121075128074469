.loader_flutter {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
}
.loader_flutter::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #0A2A66;
  animation: prixClipFix 2s linear infinite;
}