.form-outline{
  box-shadow: none;
  border: none !important;
  height: 5rem;
  width: 100%;
}

.form-input{
  border: 0.0625rem solid #0A2A66;
  position: absolute;
  width: 100%;
  height: 5rem;
  border-radius: 0.5rem;
  box-shadow: none;
}
.react-datepicker__input-container{
  width: 100%;
  height: 5rem;
}

.form-outline label {
  color: #8C9DC1;
  pointer-events: none;
  position: absolute;
  top: 1.55rem;
  left: 1.5rem;
  transition: all .5s ease-in-out;
}
.icon{
  height: 1.5rem;
    width: 1.5rem;
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
}
input[type=date]:required:invalid::-webkit-datetime-edit {
  color: transparent;
}
input[type=date]:focus::-webkit-datetime-edit{
  color:#0A2A66 !important;
}
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}
.form-outline input:read-only{
  line-height: 1.375rem;
  padding-left:1.5rem ;
  font-style: normal;
  color:#0A2A66;
  font-weight: bold;
  background-color: #E9EEF8;
  font-size: 1.125rem;
  border: none;
  outline: none;
  box-shadow: none !important;
}
.form-input:valid,
.form-input:-webkit-autofill,
.form-input:focus {
  line-height: 1.375rem;
  padding-left:1.5rem ;
  font-style: normal;
  font-size: 1.125rem !important;
  padding-top: 1.5rem;
  color:#0A2A66;
  font-weight: bold;
  background-color: #E9EEF8;
  border: 0.0625rem solid #0A2A66;
  outline: none;
  box-shadow: none !important
}

.form-input:not(:focus-within)::placeholder {
  color: transparent;
}
/* .form-outline input:required ~label, */
.form-outline span:focus~label,
.react-datepicker-wrapper ~ label,
.form-outline input:-webkit-autofill~label,
.form-outline input:read-only~label,
.form-outline input:valid ~ label,
.form-outline input:focus ~ label{
  font-size: 0.75rem;  
  font-weight: 500;
  line-height: normal;
  color:#0A2A66;
  transform: translateX(0rem) translateY(-0.5rem) !important;
}
