.custom-scrollbars__container {
  display: grid;
  height: 100%;
  grid-template: auto / 1fr 0.5rem;
  gap: 1.5rem;
  overflow: hidden;
  position: relative;
  width: calc(100% + 2rem);
  margin-right: -2rem;
}

.custom-scrollbars__content {
  -ms-overflow-style: none;
  overflow: auto;
  scrollbar-width: none;
  padding: 5rem 0rem;
}

.custom-scrollbars__content::-webkit-scrollbar {
  display: none;
}

.custom-scrollbars__track-and-thumb {
  display: block;
  height: 100%;
  position: relative;
}

.custom-scrollbars__track {
  bottom: 0;
  cursor: pointer;
  position: absolute;
  top: 5rem;
  bottom: 5rem;
  border-radius: 1rem;
}

.custom-scrollbars__thumb {
  background: #c4b5f2;
  /* height: 20.3rem !important; */
  border-radius: 1rem;
  position: absolute;
  width: 0.5rem;
  margin-top: 5rem;
}

.custom-scrollbars__thumb-daily {
  background: #0a2a66;
  /* height: 20.3rem !important; */
  border-radius: 1rem;
  position: absolute;
  width: 0.5rem;
  margin-top: 5rem;
}
