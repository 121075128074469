.btn-check {
    display: none;
}
.Title span math  {
  font-weight: 700 !important;
}
.UnitName span math{
  font-size: 1rem !important;
  font-weight: 700;
}
.tooltip-lesson {
  display: none;
}
.tooltip-lesson-container:hover .tooltip-lesson {
 display: inline-block;
}
.back-to-unit:hover{
  color: #0A2A66 !important;
}
.back-to-unit::before {
  content: "";
  position: absolute;
  width: 6.6875rem;
  height: 1.55px;
  background-color: #0A2A66; /* Màu sắc của đường gạch chân */
  bottom: 0.35rem;
  left: 4.15rem;
  z-index: 4;
}
.end-lesson-container {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 1s ease, transform 1s ease;
}

.end-lesson-container.show {
  opacity: 1;
  transform: translateX(0);
}
.end-lesson-container-left {
  opacity: 1;
  transform: translateX(50%);
  transition: opacity 1s ease, transform 1s ease;
}
.end-lesson-container-left.show {
  opacity: 1;
  transform: translateX(0%);
}