.card-monthly-exam {
  /* background-image: url(/public/images/card-monthly-exam.png); */
  width: 25rem;
  height: 25rem;
  background-repeat: no-repeat;
  background-size: auto;
  object-fit: cover;
  position: relative;
}

.card-monthly-exam .content-card-monthly-exam {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0;
  gap: 1rem;
  position: absolute;
  left: 5rem;
  top: 16.5rem;
}

.date-time-card-detail-activities {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.75rem;
  border-radius: 1rem;
  width: 18.5rem;
  position: absolute;
  height: 1.75rem;
  top: 9.94rem;
  left: 3.25rem;
}

.title-card-monthly-exam {
  position: absolute;
  top: 2.375rem;
  left: 4.5rem;
  width: 16rem;
  height: 7.125rem;
}

.card-monthly-exam .date-time-card-monthly-exam {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 0.5rem 0.75rem; */
  gap: 0.625rem;
  position: absolute;
  background-color: #ffffff;
  border-radius: 1rem;
  width: 12.25rem;
  height: 2rem;
  left: 6.375rem;
  top: 10rem;
}

.card-monthly-exam .date-time-card-monthly-exam p {
  height: 1rem;
  width: 10.75rem;
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 0.825rem;
  line-height: 1rem;
  text-transform: uppercase;
  color: #0a2a66;
  text-align: center;
}

.card-monthly-exam .content-card-monthly-exam .time-content-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  width: 15rem;
  height: 3rem;
  gap: 10px;
  background: #fed131;
  border: 0.25rem solid #0a2a66;
  color: #0a2a66;
  font-weight: 700;
  border-radius: 1rem;
  z-index: 50;
  font-family: "Montserrat";
}

.card-monthly-exam .content-card-monthly-exam .time-finished-card {
  position: absolute;
  left: 33.33%;
  right: 33.33%;
  top: -1.8rem;
  bottom: 65.84%;
  background-color: #0a2a66;
  border-radius: 1.5rem;
}

.card-monthly-exam .content-card-monthly-exam .time-finished-card p {
  position: absolute;
  left: 18.17%;
  top: 11.21%;
  font-size: 0.875rem;
  line-height: 1rem;
  text-align: center;
  color: #fafbfc;
  font-weight: 700;
}

.card-monthly-exam .content-card-monthly-exam button {
  border-radius: 0.5rem;
  border: 0;
  background-color: #0a2a66;
  color: #fafbfc;
  font-size: 1.125rem;
  font-weight: 700;
  width: 15rem;
  height: 4rem;
  text-transform: uppercase;
  cursor: pointer;
}

/* card-exams-vault  */
.card-exams-vault {
  /* background-image: url(/public/images/card-exams-vault.png); */
  width: 25rem;
  height: 25rem;
  background-repeat: no-repeat;
  background-size: auto;
  object-fit: cover;
  position: relative;
}

.title-card-exams {
  position: absolute;
  width: 9.7rem;
  height: 7.3rem;
  left: 10.5rem;
  top: 8.43rem;
}

.content-card-exams-vault .button {
  border-radius: 0.5rem;
  border: 0;
  background-color: #0a2a66;
  color: #fafbfc;
  font-size: 1.125rem;
  font-weight: 700;
  width: 15rem;
  height: 4rem;
  left: 5rem;
  top: 19.125rem;
  position: absolute;
  text-transform: uppercase;
  cursor: pointer;
}

/* card-challenges */
.card-challenges {
  /* background-image: url(/public/images/card-challenges.png); */
  width: 25rem;
  height: 25rem;
  background-repeat: no-repeat;
  background-size: auto;
  object-fit: cover;
  position: relative;
}

.content-card-challenges {
  position: absolute;
  width: 18.4rem;
  height: 10.5rem;
  left: 3.81rem;
  top: 11.81rem;
}

.title-card-challenges img {
  cursor: pointer;
}

/* card-advanced-lessons */
.card-advanced-lessons {
  /* background-image: url(/public/images/card-advanced-lessons.png); */
  width: 25rem;
  height: 25rem;
  background-repeat: no-repeat;
  background-size: auto;
  object-fit: cover;
  position: relative;
}

.content-card-advanced-lessons {
  position: absolute;
  width: 16.5rem;
  height: 12rem;
  left: 3.81rem;
  top: 10.5rem;
}

.title-card-advanced-lessons img {
  cursor: pointer;
}
