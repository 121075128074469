.main-content__change-password {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 20rem;
    margin: 0 auto;
    flex-wrap: wrap;
    align-content: center;
}

.title-change-password {
    text-align: center;
    font-weight: 700;
    font-size: 2.25rem;
    line-height: 2.75rem;
    color: #0A2A66;
}

form {
    display: flex;
    flex-direction: column;
}

.subtitle:not(:last-child) {
    margin-bottom: 2rem;
}

.forgot-password {
    text-align: center;
    font-size: 400;
    font-size: 0.875rem;
    line-height: 1rem;
    text-decoration: underline;
    cursor: pointer;
}

.form-outline .input-change-password {
    height: 5rem;
    border: 2px solid #0A2A66;
    width: 20rem;
}

.modal-warning__change-password {
    padding: 2.5rem;
    gap: 2rem;
    position: relative;
    width: 39.3rem;
    background-color: #fafbfc;
    border-radius: 2rem;
    z-index: 100;
}

.icon {
    z-index: 10;
}

.title-warning__change-password {
    text-align: center;
    font-weight: 700;
    font-size: 2.25rem;
    color: #0A2A66;
    line-height: 2.75rem;
}

.document__change-password {
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.375rem;
    text-align: center;
    color: #0A2A66;
}

.form-outline input:read-only {
    padding-top: 1.6rem;
}

.button-primary-level-page {
    display: flex;
}

.btn-next {
    display: flex;
    margin: 0 auto;
}
