.select-service::-webkit-scrollbar {
  width: 0.5rem;
  height: 20rem;
}
/* Handle */
.select-service::-webkit-scrollbar-thumb {
  background: #0a2a66;
  /* height: 5rem; */
  border-radius: 1rem;
}
.select-service::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
}
.select-service::-webkit-scrollbar-track {
  background-color: #E9EEF8; /* Màu sắc của phần track (phần nền) */
}

.box-terms::-webkit-scrollbar {
  width: 0.25rem;
  height: 5rem;
  /* margin-right: 2rem; */
} 
/* Handle */
.box-terms::-webkit-scrollbar-thumb {
  background: #0A2A66; 
  /* height: 5rem; */
  border-radius: 1rem;
  margin-right: 1rem;
}
.box-terms::-webkit-scrollbar-thumb:hover{
  cursor: pointer;
}
.box-terms::-webkit-scrollbar {
  margin-right: 2rem;
} 
.payment-result{
  width: 35rem;
}

@media (max-width: 1023px) {
  .payment-result{
    width: auto;
  }
}