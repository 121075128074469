.question-quiz {
  filter: drop-shadow(0px 6px 16px rgba(10, 42, 102, 0.25));
}

.question-quiz::-webkit-scrollbar {
  width: 0.5rem;
  height: 0rem;
}
/* Handle */
.question-quiz::-webkit-scrollbar-thumb {
  background: #0a2a66;
  /* height: 5rem; */
  border-radius: 1rem;
}
.question-quiz::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
}

.columns {
  padding: 0;
}

.modal__quiz {
  padding: 2.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  border-radius: 2rem;
}
.finished-notice-monthly-exam {
  position: relative;
}

/* .background-quiz {
  background-image: url("/public/images/background-quiz.svg");
} */

.title__loading {
  font-style: normal;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.375rem;
  color: #fff;
}

.loader__quiz {
  height: 0.5rem;
  width: 29.25rem;
  display: inline-block;
  position: relative;
  background: rgba(255, 255, 255, 0.15);
  overflow: hidden;
  border-radius: 1rem;
}
.loader__quiz::after {
  content: "";
  box-sizing: border-box;
  width: 0;
  height: 0.5rem;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  animation: animFw 5s linear infinite;
}

@keyframes animFw {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.finished-notice {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  align-items: center;
}

.finished-notice img {
  width: 12.5rem;
  height: 12.5rem;
}

.finished-notice .title-notice {
  font-weight: 700;
  font-size: 3rem;
  line-height: 3.68rem;
  text-align: center;
  color: #fafbfc;
}

.finished-notice .exam-score-card {
  width: 374px;
  height: 200px;
  position: relative;
}

.finished-notice .group-exam {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1.93rem 6rem 1.93rem 2.5rem;
  gap: 0.625rem;
  width: 15.8rem;
  top: 2.4rem;
  background: #fafbfc;
  border-radius: 5rem;
  height: 7.5rem;
  position: relative;
}

.finished-notice .group-exam p {
  text-align: center;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.81rem;
  color: #0a2a66;
}

.finished-notice .mark-group {
  position: absolute;
  background-image: url("/public/images/button/exam-score.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 12.5rem;
  height: 12.5rem;
  text-align: center;
  left: 10.5rem;
  top: -0.5rem;
}

.finished-notice .score {
  height: 100%;
  margin-top: 1.875rem;
}

.score p {
  color: #572cd1;
  font-weight: 700;
  font-size: 4.5em;
  line-height: 5.5rem;
  text-align: center;
}

.score span {
  color: #572cd1;
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 2.75rem;
  text-align: center;
}

.finished-notice .back-quiz {
  text-align: center;
  font-size: 1rem;
  line-height: 1.3rem;
  color: #fafbfc;
  cursor: pointer;
}

.exam-monthly-score {
  text-align: center;
  padding: 1.5rem 2.25rem 4rem;
  width: 12.43rem;
  height: 14.25rem;
  background: #572cd1;
  border-radius: 1rem;
}

.exam-monthly-score .score-exams-monthly p {
  font-weight: 700;
  font-size: 6rem;
  line-height: 7.3rem;
  color: #fafbfc;
  margin: -1rem 0rem;
}

.exam-monthly-score .score-exams-monthly span {
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.43rem;
  color: #fafbfc;
}

.achievement-exam {
  position: absolute;
  top: 31rem;
}

.achievement-exam img {
  width: 14.31rem;
  height: 5.625rem;
  object-fit: contain;
}
