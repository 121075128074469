.content-monthly-exam {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;
  padding: 0rem;
}

.content-monthly-exam h3 {
  color: #0a2a66;
  font-size: 2rem;
  line-height: 2.43rem;
  text-align: center;
  font-weight: 700;
}

.content-detail-monthly-exam {
  background-position: center;
  background-repeat: no-repeat;
  width: 54rem;
  height: 16.875rem;
  border-radius: 1rem;
  position: relative;
  background-size: cover;
  border-radius: 1rem;
    box-shadow: rgb(10, 42, 102) 2px 4px 0px 0px;
    border: 1.5px solid var(--kurio-main-colors-kurio-main-black, #0A2A66)
  /* background-image: url("/public/images/background-monthly-exam.svg"); */
}

.date-time-card-detail-monthly-exam {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.75rem;
  border-radius: 1rem;
  width: 18.5rem;
  position: absolute;
  height: 1.75rem;
  top: 9.5rem;
  left: 1rem;
}

.card-detail-monthly-exam-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0;
  gap: 1rem;
  z-index: 1;
  position: absolute;
  left: 36.5rem;
  top: 4.56rem;
}

.card-detail-monthly-exam-right button {
  border-radius: 0.5rem;
  border: 0;
  background-color: #0a2a66;
  color: #fafbfc;
  font-size: 1.125rem;
  font-weight: 700;
  width: 15rem;
  height: 4rem;
  text-transform: uppercase;
  cursor: pointer;
}

.time-finished-card-detail {
  position: absolute;
  left: 33.33%;
  right: 33.33%;
  top: -1.8rem;
  bottom: 65.84%;
  background-color: #0a2a66;
  border-radius: 1.5rem;
}

.time-finished-card-detail p {
  position: absolute;
  left: 19.17%;
  top: 11.21%;
  font-size: 0.875rem;
  line-height: 1rem;
  text-align: center;
  color: #fafbfc;
  font-weight: 700;
}

.card-detail-monthly-exam-right .time-content-card-detail {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  width: 15rem;
  height: 3rem;
  font-size: 1.125rem;
  gap: 10px;
  background: #fed131;
  border: 0.25rem solid #0a2a66;
  color: #0a2a66;
  font-weight: 700;
  border-radius: 1rem;
  z-index: 200;
  position: relative;
  margin: 0 auto;
}

.card-detail-monthly-exam-solution .time-content-card-detail {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  width: 10rem;
  font-size: 0.75rem;
  height: 2.5rem;
  gap: 10px;
  background: #fed131;
  border: 0.25rem solid #0a2a66;
  color: #0a2a66;
  font-weight: 700;
  border-radius: 1rem;
  z-index: 1;
  position: relative;
  margin: 0 auto;
}

.ranking-and-answer-monthly-exam {
  display: flex;
  flex-direction: row;
  gap: 1.75rem;
  align-items: flex-start;
  padding: 0;
  width: 54rem;
  height: 15rem;
}

.ranking-monthly-exam {
  /* background-image: url("/public/images/background-ranking-monthly.svg"); */
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  width: 26.25rem;
  height: 15rem;
  border-radius: 1rem;
  box-shadow: rgb(10, 42, 102) 2px 4px 0px 0px;
  border: 1.5px solid var(--kurio-main-colors-kurio-main-black, #0A2A66)
}

.ranking-monthly-exam .one-ranking {
  position: absolute;
  top: 5.3rem;
  font-size: 0.75rem;
  left: 16.2rem;
  color: #fafbfc;
  text-align: center;
  font-weight: 700;
}

.ranking-monthly-exam .two-ranking {
  position: absolute;
  top: 7.3rem;
  font-size: 0.75rem;
  left: 10.5rem;
  color: #fafbfc;
  text-align: center;
  font-weight: 700;
}

.ranking-monthly-exam .three-ranking {
  position: absolute;
  top: 8.3rem;
  font-size: 0.75rem;
  left: 20.6rem;
  color: #fafbfc;
  text-align: center;
  font-weight: 700;
}

.see-solution-exam {
  width: 26rem;
  border-radius: 1rem;
  height: 15rem;
  /* background-image: url("/public/images/background-see-solution-exam.svg"); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 0rem 1.75rem;
  position: relative;
  box-shadow: rgb(10, 42, 102) 2px 4px 0px 0px;
  border: 1.5px solid var(--kurio-main-colors-kurio-main-black, #0A2A66)
}

.see-solution-exam .image-title-exam {
  width: 15.3125rem;
  height: 9.125rem;
  object-fit: cover;
  position: absolute;
  top: 1.1875rem;
  left: 5.5rem;
}

.see-solution-exam button {
  position: absolute;
  top: 11rem;
  left: 6rem;
}

.popup-ranking-monthly-exam {
  /* background-image: url("/public/images/background-top-ranking-popup.png"); */
  height: 18.75rem;
  width: 60rem;
  background-repeat: no-repeat;
  position: relative;
  top: -12rem;
  background-position: center;
  background-size: cover;
}

.header-top-popup-ranking {
  position: relative;
  height: 16.75rem;
}

.header-top-popup-ranking .title-ranking-popup {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  width: 27rem;
  margin-top: 2rem;
}

.header-top-popup-ranking .title-ranking-popup .ranking-personal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.header-top-popup-ranking .title-ranking-popup .ranking-personal p {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 96%;
  color: #fafbfc;
  text-align: center;
}

.header-top-popup-ranking .title-ranking-popup .ranking-personal span {
  font-weight: 700;
  font-size: 4rem;
  line-height: 96%;
  text-align: center;
  color: #fafbfc;
}

.header-top-popup-ranking .ranking-popup-monthly-exam {
  /* background-image: url("/public/images/image-ranking-exam.svg"); */
  width: 31rem;
  height: 14.5625rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  bottom: 0rem;
  right: 3.5rem;
}

.header-top-popup-ranking .ranking-popup-monthly-exam .one-popup-ranking {
  position: absolute;
  top: 5.3rem;
  left: 14.3rem;
  font-size: 1.1rem;
  line-height: 1.375rem;
  color: #fafbfc;
  font-weight: 700;
}

.header-top-popup-ranking .ranking-popup-monthly-exam .two-popup-ranking {
  position: absolute;
  top: 6.8rem;
  left: 4rem;
  font-size: 1.1rem;
  font-weight: 700;
  line-height: 1.375rem;
  color: #fafbfc;
}

.header-top-popup-ranking .ranking-popup-monthly-exam .three-popup-ranking {
  position: absolute;
  top: 8.2rem;
  left: 23.3rem;
  font-weight: 700;
  font-size: 1.1rem;
  line-height: 1.375rem;
  color: #fafbfc;
}

.main-content-ranking {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 2.5rem 3.75rem;
  gap: 1.25rem;
  background: #fafbfc;
  border-radius: 0rem 0rem 2.5rem 2.5rem;
  width: 60rem;
  position: relative;
}

.main-content-ranking .item-ranking {
  width: 25rem;
  height: 2.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #f7f1df;
  border-radius: 0.5rem;
  align-items: center;
  padding: 0rem 1.25rem;
}

.main-content-ranking .item-ranking span {
  background: #fed131;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
  color: #0a2a66;
  padding: 0.5rem;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
}

.main-content-ranking .item-ranking .name-user {
  color: #0a2a66;
  font-size: 1.1rem;
  line-height: 96%;
  font-weight: 500;
}
.main-content-ranking .item-ranking .exam-score {
  color: #0a2a66;
  font-size: 1.1rem;
  line-height: 96%;
  font-weight: 700;
  width:1.875rem;
  text-align: center;
}

.pagination-ranking {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  bottom: 1.25rem;
  justify-content: center;
  position: relative;
  width: 60rem;
  gap: 16px;
  filter: drop-shadow(0px 6px 16px rgba(10, 42, 102, 0.25));
}

.pagination-ranking .slide-pagination {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0.5rem 1.5rem;
  gap: 0.75rem;
  width: 9.75rem;
  height: 1.75rem;
  background: #fafbfc;
  border-radius: 1rem;
}

.pagination-ranking .slide-pagination span {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background-color: #d5d5d5;
  cursor: pointer;
}

.pagination-ranking .slide-pagination span:nth-child(1) {
  background: #0a2a66;
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
}
.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #fff;
  animation: prixClipFix 2s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

.card-detail-monthly-exam-solution {
  padding: 0;
  position: relative;
  gap: 1rem;
  left: 0rem;
  top: 11rem;
}

.card-detail-monthly-exam-solution button {
  border-radius: 0.5rem;
  border: 0;
  background-color: #0a2a66;
  color: #fafbfc;
  font-size: 1.125rem;
  font-weight: 700;
  width: 10rem;
  height: 4rem;
  text-transform: uppercase;
  cursor: pointer;
}

.time-finished-card-detail-solution {
  position: absolute;
  left: 38.5%;
  right: 33.33%;
  width: 5.25rem;
  top: -1.3rem;
  bottom: 65.84%;
  background-color: #0a2a66;
  border-radius: 1.5rem;
}

.time-finished-card-detail-solution p {
  position: absolute;
  left: 11.17%;
  top: 11.21%;
  font-size: 0.675rem;
  /* line-height: 1rem; */
  text-align: center;
  color: #fafbfc;
  font-weight: 700;
}

.show-solution-monthly .time-finished-card-show-solution {
  position: absolute;
  left: 8%;
  right: 12.33%;
  width: 8.25rem;
  top: -1.1rem;
  bottom: 72.84%;
  background-color: #0a2a66;
  border-radius: 1.5rem;
}

.show-solution-monthly .time-finished-card-show-solution p {
  position: absolute;
  left: 12.17%;
  top: 11.21%;
  font-size: 0.675rem;
  /* line-height: 1rem; */
  text-align: center;
  color: #fafbfc;
  font-weight: 700;
}

.time-content-card-show-solution {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  width: 10rem;
  margin: 0 auto;
  height: 2.5rem;
  gap: 10px;
  background: #d6cdf2;
  border: 0.25rem solid #0a2a66;
  border-radius: 1rem;
  font-family: "Montserrat";
  position: relative;
}

.time-content-card-show-solution p {
  color: #572cd1;
  font-weight: 700;
  font-size: 1.2rem;
  text-align: center;
}

.show-solution-monthly {
  display: flex;
  margin: 0rem 0.5rem;
}

.show-solution-monthly button {
  position: absolute;
  top: 11rem;
  left: 6rem;
  margin: 0rem -4.5rem;
}
