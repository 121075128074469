.container-landingpage {
  max-width: 1280px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
}

.max-width-landingpage {
  max-width: 1280px;
  margin: 0 auto;
}
.displayNone {
  display: none;
}

.fixedHeader {
  position: fixed;
  display: block !important;
  top: 0;
  left: 0;
  width: 100%;
  animation: fadeInDown 1s ease forwards;
  animation-delay: 1s ease;
  z-index: 11;
  /* Sử dụng forwards để giữ cho phần tử ở vị trí cuối cùng của animation */
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.remote-fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  animation: fadeUpDown 1s ease-out forwards;
  /* Adjust animation duration and timing function */
}

@keyframes fadeUpDown {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(-100%);
  }
}

.btn-landingpage {
  background: #572CD1;
}

/* .btn-landingpage:hover { */
  /* cursor: pointer;
  background: #E64934;
  box-shadow: -5px 5px 0px 1px #572CD1, -10px 10px 0px 1px #1D95F7; */
  /* Đặt 2 lớp box shadow với màu khác nhau */
/* } */

@media (min-width: 1024px) {
  #mobile-landingpage {
    display: none;
  }

  #tablet-landingpage {
    display: none;
  }

  .container-landingpage {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
  }
  .max-width-landingpage{
    width: 100%;
    margin: 0 auto;
  }
}

@media (min-width: 768px) and (max-width: 1100px) {
  #mobile-landingpage {
    display: none;
  }

  #dekstop-landingpage {
    display: none !important;
  }

  #tablet-landingpage {
    display: block !important;
  }

  .container-landingpage {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
  }
  .max-width-landingpage{
    width: 100%;
    margin: 0 auto;
  }
}

@media (min-width: 451px) and (max-width: 767px) {
  #dekstop-landingpage {
    display: none !important;
  }

  #tablet-landingpage {
    display: none;
  }

  .container-landingpage {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
  }
  .max-width-landingpage{
    width: 100%;
    margin: 0 auto;
  }

  .icon-cancel {
    top: 15% !important;
    right: 4% !important;
    width: 2.5rem !important;
    height: 2.5rem !important;
  }

  .icon-cancel img {
    width: 0.8rem !important;
  }

  .btn-mobile-popup {
    width: 14.5rem !important;
    height: 3.5rem !important;
  }

  .btn-mobile-popup p {
    font-size: 1.25rem !important;
  }

  .landingpage_horizontal-intro-payment-section p {
    margin: 17px 0;
    font-size: 0.875rem !important;
    line-height: normal !important;
  }

  .landingpage_horizontal-intro-payment-section h1 {
    font-size: 1.5rem !important;
    line-height: normal !important;
  }

  .landingpage_horizontal-intro-payment-section h2 {
    font-size: 1.125rem !important;
  }
}

@media (min-width: 100px) and (max-width: 451px) {
  #dekstop-landingpage {
    display: none !important;
  }

  #tablet-landingpage {
    display: none;
  }

  .container-landingpage {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
  }
  .max-width-landingpage{
    width: 100%;
    margin: 0 auto;
  }

  .landingpage_horizontal-intro-payment-section p {
    margin: 17px 0;
    font-size: 0.875rem !important;
    line-height: normal !important;
  }

  .landingpage_horizontal-intro-payment-section h1 {
    font-size: 1.5rem !important;
    line-height: normal !important;
  }

  .landingpage_horizontal-intro-payment-section h2 {
    font-size: 1.125rem !important;
  }
}


/* Payment Policy */

.landingpage_horizontal-intro-payment-section p {
  margin: 17px 0;
  font-size: 1rem;
}

.landingpage_horizontal-intro-payment-section h2 {
  font-size: 1.5rem;
}