/* .menu-item:hover {
  background-color: #e9eef8 !important;
  display: flex;
  padding: 0.625rem 1rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
  border-radius: 1rem;
  font-weight: 700;
} */
.menu-item {
  background-color: #FAFBFC;
}
