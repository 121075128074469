canvas {
  /* width: 750px !important;  */
  /* background: #E9EEF8; */
  margin-top: 7px;
  border-radius: 1rem;
  position: relative,
}

.ranking-profile {
  padding: 10px 10px;
  height: 370px;
  padding: 1.94rem 3.17rem;
  background: #e9eef8;
  border-radius: 1rem;
}


