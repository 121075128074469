.box-privacy-policy::-webkit-scrollbar {
  width: 0.5rem;
  height: 5rem;
  margin-right: 2rem;
} 
/* Handle */
.box-privacy-policy::-webkit-scrollbar-thumb {
  background: #0A2A66; 
  /* height: 5rem; */
  border-radius: 1rem;
  margin-right: 1rem;
}
.box-privacy-policy::-webkit-scrollbar-thumb:hover{
  cursor: pointer;
}