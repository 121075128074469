.lats-lesson-title span math  {
  font-size: 1.2rem !important;
}
.list-unit-name span math  {
  font-size: 1.2rem !important;
  font-weight: 700 !important;
}
.last-lesson-unit-name span math  {
  text-transform: uppercase;
  font-size: 0.9rem !important;
}
.unit-title:hover,
.unit-item:hover~.row-solution,
.unit-item:hover~.unit-title {
  /* background-color: #FAFBFC !important; */
  filter:drop-shadow(0px 0.375rem 1rem rgba(10, 42, 102, 0.25));
  border-radius: 1rem;
}
.current-lesson:hover {
  /* background-color: #FAFBFC !important; */
  box-shadow: 0rem 0.375rem 1rem 0rem rgba(10, 42, 102, 0.25);
}

.title-grade-unit__list-unit {
  padding: 0.5rem 0.75rem;
  /* background-color: #E9EEF8; */
  border-radius: 1rem;
  display: inline-block;
  height: 2rem;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1rem;
  text-align: left;
  color: #0a2a66;
  max-width: 30rem;
}