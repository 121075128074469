@import url("https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap");

.wrapper__menu-top-bar {
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2.5rem;
  flex-direction: row;
  height: 6.5rem;
}
.button-change-grade:hover {
  filter: drop-shadow(0rem 0.375rem 0.75rem rgba(10, 42, 102, 0.25));
  color: #fed131;
}
.button-change-grade{
  color: #fafbfc;
}

.grade-category {
  flex-direction: row;
  align-items: center;
  padding: 0rem;
  gap: 1rem;
  /* width: 15.1rem; */
}

.grade-category p {
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.375rem;
  color: #0a2a66;
  text-transform: uppercase;
}

.grade-category span {
  width: 10rem;
  height: 2.5rem;
  border: 1px solid #0a2a66;
  border-radius: 1.25rem;
}

.grade-category span > p {
  text-align: center;
  line-height: 2.4rem;
  font-weight: 700;
  font-size: 1rem;
  text-transform: uppercase;
  color: #0a2a66;
}

.grade-service {
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0rem;
  gap: 1.5rem;
  height: 3.5rem;
  cursor: pointer;
}

.grade-service > .item-grade-service_noti img {
  display: block;
}

.item-grade_user-information-fix {
  align-items: center;
  padding: 0.5rem 0.75rem;
  gap: 0.75rem;
  width: 10.5rem;
  height: 3.5rem;
  background-color: #e9eef8;
  flex-direction: row;
  border-radius: 1rem;
}

.item-grade_user-information-fix p {
  font-weight: 700;
  color: #0a2a66;
  line-height: 0.93rem;
  font-size: 0.75rem;
}

.item-grade_user-information-fix p span {
  line-height: 1.375rem;
  font-size: 1.125rem;
}

.item-grade_user-information-fix div {
  background-color: #fafbfc;
  width: 1.375rem;
  height: 1.375rem;
  border-radius: 50%;
  text-align: center;
  line-height: 1.1rem;
}

.item-grade_user-information {
  align-items: center;
  padding: 0.5rem 0.75rem;
  gap: 0.75rem;
  width: 12.5rem !important;
  height: 3.5rem;
  background-color: #e9eef8;
  flex-direction: row;
  border-radius: 1rem;
}

.item-grade_user-information p {
  font-weight: 700;
  color: #0a2a66;
  line-height: 0.93rem;
  font-size: 0.75rem;
}

.item-grade_user-information p span {
  line-height: 1.375rem;
  font-size: 1.125rem;
}

.item-grade_user-information div {
  background-color: #fafbfc;
  width: 1.375rem !important;
  height: 1.375rem !important;
  border-radius: 50%;
  text-align: center;
  line-height: 1.1rem;
}

.accmenu__button-click {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 4.5rem !important;
  left: -4rem !important;
  z-index: 100;
  padding: 1.5rem 1rem !important;
  background-color: #fafbfc;
  border-radius: 1rem;
  width: 15.5rem;
  border: 1.5px solid #0a2a66;
  box-shadow: 0.125rem 0.125rem 0rem 0rem #0a2a66;
}

.accmenu__button-click ul {
  gap: 1rem;
  display: flex;
  flex-direction: column;
}

.accmenu__button-click li,
.accmenu__button-click a {
  padding: 1rem;
  font-size: 0.875rem;
  line-height: 1.06rem;
  color: #0a2a66;
  font-weight: 700;
  display: flex;
  align-items: center;
  background: #E9EEF8;
  border-radius: 1rem;
  gap: 1rem;
  cursor: pointer;
}

.accmenu__button-click li:hover,
.accmenu__button-click a:hover {
  background: #FAFBFC;
  border-radius: 1rem;
  padding: 1rem;
  color: #0a2a66;
  font-weight: 600;
  border: 1.5px solid #0a2a66;
  box-shadow: 0.125rem 0.125rem 0rem 0rem #0a2a66;
}

.accmenu__button-click__grade {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 4rem;
  left: 1rem;
  z-index: 100;
  padding: 1.5rem;
  background-color: #fafbfc;
  border-radius: 1rem;
  width: 11rem;
  box-shadow: 0rem 0.375rem 1rem rgba(10, 42, 102, 0.25);
}

.accmenu__button-click__grade li {
  padding: 0.6rem 1rem;
  font-size: 0.875rem;
  line-height: 1.06rem;
  color: #0a2a66;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 1rem;
  height: 3.75rem;
  cursor: pointer;
}

.accmenu__button-click__grade li:hover {
  background: #e9eef8;
  border-radius: 1rem;
  padding: 1rem;
  height: 3.75rem;
  color: #0a2a66;
  font-weight: 600;
}

.nar-bar-exam-monthly {
  position: absolute;
  width: 16.75rem;
  margin-left: 4.84rem;
  top: 2.5rem;
  height: 17.5rem;
  cursor: pointer;
  border-radius: 1rem;
}

.nar-bar-exam-monthly .date-time-card-monthly-exam {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.75rem;
  gap: 0.625rem;
  position: absolute;
  background-color: #ffffff;
  border-radius: 1rem;
  width: 12.25rem;
  height: 2rem;
  left: 3.25rem;
  top: 9.94rem;
}

.nar-bar-exam-monthly .date-time-card-monthly-exam p {
  width: 10.75rem;
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 0.825rem;
  line-height: 1rem;
  text-transform: uppercase;
  color: #0a2a66;
  text-align: center;
}

.nar-bar-exam-monthly .content-card-monthly-exam {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 12.5rem;
  margin-left: 3.3rem;
  position: relative;
  z-index: 100;
}

.nar-bar-exam-monthly .content-card-monthly-exam .time-content-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  width: 15rem;
  height: 3rem;
  gap: 10px;
  background: #fed131;
  border: 0.25rem solid #0a2a66;
  color: #0a2a66;
  font-weight: 700;
  font-size: 1.1rem;
  border-radius: 1rem;
  z-index: 100;
  position: relative;
  font-family: "Montserrat";
}

.nar-bar-exam-monthly .content-card-monthly-exam .time-finished-card {
  position: absolute;
  left: 19.33%;
  right: 66.33%;
  top: 15.2rem;
  bottom: 12.84%;
  background-color: #0a2a66;
  border-radius: 1.5rem;
}

.nar-bar-exam-monthly .content-card-monthly-exam .time-finished-card p {
  position: absolute;
  left: 23.17%;
  top: 12.21%;
  font-size: 0.875rem;
  /* line-height: 1rem; */
  text-align: center;
  color: #fafbfc;
  font-weight: 700;
}

.nar-bar-exam-monthly .content-card-monthly-exam .button {
  margin-top: -0.6rem;
}

/* ADD CSS SUCCESS */

.nar-bar-exam-monthly .add-css-content-card-monthly-exam {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  margin-top: 12.5rem;
  margin-left: 3.3rem;
  position: relative;
  z-index: 100;
}

.nar-bar-exam-monthly .add-css-content-card-monthly-exam .time-content-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  width: 15rem;
  height: 3rem;
  gap: 10px;
  background: #fed131;
  border: 0.25rem solid #0a2a66;
  color: #0a2a66;
  font-weight: 700;
  font-size: 1.1rem;
  border-radius: 1rem;
  z-index: 100;
  position: relative;
  font-family: "Montserrat";
}

.nar-bar-exam-monthly .add-css-content-card-monthly-exam .time-finished-card {
  position: absolute;
  left: 29.33%;
  right: 31.33%;
  top: -1.7rem;
  bottom: 75.84%;
  background-color: #0a2a66;
  border-radius: 1.5rem;
}

.nar-bar-exam-monthly .add-css-content-card-monthly-exam .time-finished-card p {
  position: absolute;
  left: 21.17%;
  top: 12.21%;
  font-size: 0.875rem;
  /* line-height: 1rem; */
  text-align: center;
  color: #fafbfc;
  font-weight: 700;
}

.nar-bar-exam-monthly .add-css-content-card-monthly-exam .button {
  margin-top: -0.6rem;
}

.nar-bar-exam-monthly .add-css-content-card-monthly-exam .add-create-time {
  position: absolute;
  left: 19.33%;
  right: 66.33%;
  top: 15.4rem;
  bottom: 12.84%;
  background-color: #0a2a66;
  border-radius: 1.5rem;
}

.nar-bar-exam-monthly .add-css-content-card-monthly-exam .add-create-time p {
  position: absolute;
  left: 23.17%;
  top: 12.21%;
  font-size: 0.875rem;
  /* line-height: 1rem; */
  text-align: center;
  color: #fafbfc;
  font-weight: 700;
}

/* Create CSS */

.nar-bar-exam-monthly .content-card-monthly-exam .add-create-time {
  position: absolute;
  left: 30.33%;
  right: 29.33%;
  top: -1.9rem;
  bottom: 78.84%;
  background-color: #0a2a66;
  border-radius: 1.5rem;
}

.nar-bar-exam-monthly .content-card-monthly-exam .add-create-time p {
  position: absolute;
  left: 20.17%;
  top: 12.21%;
  font-size: 0.875rem;
  /* line-height: 1rem; */
  text-align: center;
  color: #fafbfc;
  font-weight: 700;
}

/* Lesson */

.title-grade-unit__popup-narbar {
  padding: 0.25rem 0.5rem;
  background-color: #e9eef8;
  border-radius: 1rem;
  display: inline-block;
  height: 1.5rem;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1rem;
  text-align: left;
  color: #0a2a66;
  max-width: 12.625rem;
}

.title-grade-unit__popup-narbar p {
  max-width: 11.625rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.status__grade-unit-narbar {
  background-color: #0a2a66;
  width: 15.47481rem;
  height: 14rem;
  border-radius: 0.5rem;
  text-align: center;
  position: absolute;
  z-index: 1;
  /* position: relative; */
  top: -1.5rem;
  right: 9rem;
  box-shadow: 0px 6px 16px 0px rgba(10, 42, 102, 0.25);
  padding: 0.7rem 0rem;
}

.status__grade-unit-narbar .after-background_grade-unit {
  width: 15rem;
  height: 9.70969rem;
  margin-top: 9rem;
  margin: 0 auto;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  background: #fff;
}

.status__grade-unit-narbar .title__grade-unit {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.4rem;
  margin-bottom: 1.13rem;
}

.popup-daily-mission {
  opacity: 0;
  transform: translateX(-20%);
  transition: opacity 1s ease, transform 2s ease;
}

.popup-daily-mission.show {
  opacity: 1;
  transform: translateX(0%);
}
/* .content-document-home-page .document-grade-unit-narbar {
  font-weight: 500;
  text-align: center;
  font-size: 1.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 140%;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
} */
.dropdown-trigger .item-grade_user-information,.dropdown-trigger .dropdown-background{
  width: 12.5rem !important;
}
