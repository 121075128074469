/* mtd is a cell in katex, mostly used for {align}
   mtd align center by default by katex, we align left */
mtd {
    text-align: -webkit-left !important;
    padding: 7.2px 0 !important;
}

/* make katex math inline */
math {
    display: inline-block;
    font-size: 1.3rem;
}
.react-sweet-progress-symbol {
  display: none;
}