.user-infomation__main-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5rem;
  padding: 2.5rem 0rem
}

.user-infomation__left {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0rem;
  gap: 1.5rem;
}

.avatar__user-information {
  width: 15rem;
  height: 15rem;
  border: 0.625rem solid #e9eef8;
  border-radius: 50%;
  box-shadow: 0.125rem 0.25rem 0rem 0rem #0a2a66;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .user-information__right {
  width: 40rem;
} */

.user-information__right .btn-student {
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  background-color: #fed131;
  width: 8.375rem;
  height: 2.0625rem;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: #572cd1;
  text-transform: capitalize;
  font-weight: 700;
  border: none;
  margin-bottom: 0.5rem;
}

.btn-premiumto {
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  background-color: #fafbfc;
  width: 11.4375rem;
  height: 2.0625rem;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: #0a2a66;
  text-transform: capitalize;
  font-weight: 700;
  border: none;
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
}

.user-information__right .btn-parent {
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  background-color: #e9eef8;
  width: 10.8125rem;
  height: 2.0625rem;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: #0a2a66;
  text-transform: capitalize;
  font-weight: 700;
  border: none;
  margin-bottom: 0.5rem;
}

.title-user-information {
  display: flex;
  justify-content: space-between;
}

.title-user-information h1 {
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.4375rem;
  color: #0a2a66;
  margin-bottom: 1.5rem;
}

.classify-user-information {
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-direction: row;
}

.classify-user-information .focus {
  padding: 0.75rem 1.5rem;
  background-color: #0a2a66;
  border-radius: 1rem;
  cursor: pointer;
}

.classify-user-information .focus p {
  color: #fafbfc;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.375rem;
}

.classify-user-information .no-focus {
  cursor: pointer;
  border-radius: 1rem;
  border: 1.5px solid var(--kurio-main-colors-kurio-main-black, #0a2a66);
  background: var(--kurio-main-colors-kurio-main-white, #fafbfc);
  /* block shadow small */
  box-shadow: 2px 2px 0px 0px #0a2a66;
  padding: 0.75rem 1.5rem;
}

.classify-user-information .no-focus p {
  color: #0a2a66;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.375rem;
}

.form-user-information {
  margin-top: 1.5rem;
  width: 40rem;
  height: 23rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0rem;
  gap: 1rem;
}

.form-user-information .form-outline input:read-only {
  font-size: 1rem;
  font-weight: 700;
  padding-top: 1.6rem;
}

.form-user-information .form-outline input:read-only~label {
  width: auto;
  color: #0a2a66;
}

.input__name-user-infomation {
  width: 40rem;
}

.input__phonenumber-user-infomation {
  width: 15rem;
}

.input__email-user-infomation {
  width: 24rem;
}

.input__date-user-infomation {
  width: 40rem;
}

.form-outline .input__password-user-infomation {
  /* margin-right: 9.1rem; */
  width: 24rem;
}

.form-input {
  height: 5rem;
}

.btn-cancel button {
  width: 0rem;
}

.styleBackground {
  margin-top: 1.5rem;
  width: 40rem;
  height: 23rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0rem;
  gap: 1rem;
}

.styleBackground .form-input {
  font-size: 1rem;
  font-weight: 700;
  padding-top: 1.6rem;
  background-color: #fafbfc;
  border: 1px solid #333;
}

.styleBackground .form-outline input:valid~label {
  width: 40rem;
  color: #0a2a66;
}

.styleBackground .form-outline input:read-only~label {
  width: 40rem;
  color: #0a2a66;
}

.react-datepicker-wrapper :focus~label {
  font-size: 10rem;
}

.react-datepicker__input-container .form-input:focus {
  line-height: 1.375rem;
  padding-left: 1.5rem;
  font-style: normal;
  color: #0a2a66;
  font-weight: bold;
  background-color: #e9eef8;
  border: 0.0625rem solid #0a2a66;
  outline: none;
  box-shadow: none !important;
}

.styleBackground .form-outline label {
  width: 40rem;
  pointer-events: none;
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  transition: all 0.5s ease-in-out;
  font-size: 0.75rem;
  color: #0a2a66;
}

.btn-check {
  border: 1px solid red;
}

/* .box-image:nth-child(odd) {
  background-color: #e9eef8;
}
.box-image:nth-child(even) {
  background-color: #bec9e0;
} */

.box-image {
  width: 5rem;
  height: 5rem;
  border-radius: 100%;
}

/* Modal Update Image */

.modal-update-image {
  padding: 2.5rem;
  background-color: #ffffff;
  gap: 0.6rem;
  height: 28.5rem;
  border-radius: 2rem;
}

.btn-update-image .button.is-secondary-button-default-color {
  width: 22.3rem;
}

.btn-update-image .button.is-secondary-button-clicked-color {
  width: 21.5rem;
}

.title-box-image {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.375rem;
  color: #0a2a66;
}

.group-old-image::-webkit-scrollbar {
  width: 0.5rem;
  height: 3rem;
}

/* Handle */
.group-old-image::-webkit-scrollbar-thumb {
  background: #0a2a66;
  /* height: 5rem; */
  border-radius: 1rem;
}

.preview-image span {
  margin: 0 auto;
  padding: 0.25rem 1rem;
  border-radius: 0.5rem;
  background-color: #e9eef8;
  width: 5.5rem;
  height: 1.5rem;
  color: #0a2a66;
  font-size: 0.875rem;
  line-height: 1rem;
  text-align: center;
}

.preview-image .ellipsis {
  text-align: center;
  font-size: 0.875rem;
  font-weight: 500;
  color: #0a2a66;
  width: 8.125rem;
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  /* Hiển thị ba chấm khi văn bản bị cắt ngắn */
  white-space: nowrap;
}

.preview-image-border {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
}