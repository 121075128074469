.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  text-align: center;
  gap: 10px;

  .document-container {
    text-align: left;
  }

  .input {
    width: 300px;
  }

  .button {
    margin: 0 5px;
  }

  .box .button {
    margin-top: 10px;
  }
}