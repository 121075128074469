.container-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0rem 0rem 4rem;
  gap: 1.5rem;
}

.container-wrapper h3 {
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.43rem;
  text-align: center;
  color: #fafbfc;
}

.container-wrapper .filter-exams {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0rem;
  gap: 1rem;
  justify-content: center;
  position: relative;
}

.container-wrapper .option-filter-year {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 15rem;
  align-items: center;
  padding: 0rem 1.25rem;
  height: 3rem;
  background: #fafbfc;
  border: 0.0625rem solid #0a2a66;
  border-radius: 0.5rem;
  cursor: pointer;
}

.option-filter-year p {
  font-size: 1.1rem;
  font-weight: 700;
  color: #0a2a66;
  line-height: 1.375rem;
}

.container-wrapper .option-filter-year:hover {
  background: #d9e1f0;
}

.filter-exams .accmenu__button-click__grade {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 4rem;
  left: 2rem;
  z-index: 1;
  padding: 1rem;
  height: 20.8rem;
  overflow: hidden;
  overflow-y: scroll;
  background-color: #fafbfc;
  border-radius: 0.5rem;
  width: 15rem;
  box-shadow: 0rem 0.375rem 1rem rgba(10, 42, 102, 0.25);
}

.filter-exams .accmenu__button-click__grade li {
  padding: 0.6rem 1rem;
  font-size: 0.875rem;
  line-height: 1.06rem;
  color: #0a2a66;
  font-weight: 500;
  display: flex;
  align-items: center;
  height: 3.75rem;
  cursor: pointer;
}

.filter-exams .accmenu__button-click__grade li:hover {
  background: #e9eef8;
  border-radius: 1rem;
  padding: 1rem;
  height: 3.75rem;
  color: #0a2a66;
  font-weight: 600;
}

.filter-exams .accmenu__button-click__grade::-webkit-scrollbar {
  width: 0.5rem;
  height: 3rem;
}
/* Handle */
.filter-exams .accmenu__button-click__grade::-webkit-scrollbar-thumb {
  background: #0a2a66;
  /* height: 5rem; */
  border-radius: 1rem;
}

.content-exams {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1.5rem;
}

.content-exams .item-exams {
  position: relative;
  border: 1.5px solid #0a2a66;
  box-shadow: 0.125rem 0.125rem 0rem 0rem #0a2a66;
  border-radius: 1rem;
}

.content-exams .item-exams .item-exams-top {
  width: 15rem;
  height: 19.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.content-exams .item-exams .item-exams-top img {
  width: 15rem;
  height: 19.5rem;
  object-fit: cover;
}

.content-exams .item-exams .item-exams-top .content-item .title-name {
  position: absolute;
  top: 2.1875rem;
  left: 1.875rem;
  width: 3.625rem;
  font-size: 1.1rem;
  line-height: 1.375rem;
  color: #0a2a66;
  font-weight: 700;
}

.content-exams .item-exams .item-exams-top .content-item .title-name span {
  top: 3.4375rem;
  left: 1.875rem;
  width: 9.625rem;
  display: inline-block;
  font-size: 2rem;
  line-height: 2.4375rem;
  overflow: hidden;
  font-weight: 700;
}

.content-exams .item-exams .item-exams-top .content-item button {
  top: -3.6rem;
  position: absolute;
  left: 2.575rem;
}


.content-exams .item-exams .item-exams-top .content-item .btn-exam-question button {
  top: -3.35rem;
  position: absolute;
  left: 2.575rem;
}

.content-exams .item-exams .item-exams-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  width: 15rem;
  height: 4rem;
  background: #e9eef8;
  border-radius: 0rem 0rem 0.8rem 0.9rem;
  border-top: 2.5px solid #0a2a66;
}

.content-exams .item-exams .item-exams-bottom .title-item-exams {
  width: 5.4375rem;
  font-size: 0.875rem;
  line-height: 1rem;
  text-transform: uppercase;
  color: #0a2a66;
  font-weight: 700;
}

.pagination-exams .pagination-list {
  gap: 1.5rem;
}

.pagination-exams .pagination-list:hover {
  cursor: pointer;
}