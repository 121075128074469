@import url("https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Sriracha&display=swap');

.main-caculator-point::-webkit-scrollbar {
  width: 0.5rem;
  height: 5rem;
  background: #e9eef8;
  border-radius: 1rem;
}
/* Handle */
.main-caculator-point::-webkit-scrollbar-thumb {
  background: #0a2a66;
  height: 5rem;
  border-radius: 1rem;
}
.video-intro div iframe{
  border-radius: 2.5rem;
}

.content-hub {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 2rem;
  gap: 2rem;
  width: 60rem;
  margin: 0 auto;
}

.progress-bar-container {
  width: 13.625rem;
  height: 1rem;
  background-color: #fed131; /* Màu nền (màu vàng) */
  position: relative;
  overflow: hidden;
  border-radius: 0.5rem;
}

.progress-bar-nav-bar {
  width: 10rem;
  height: 1rem;
  background-color: #fed131; /* Màu nền (màu vàng) */
  position: relative;
  overflow: hidden;
  border-radius: 0.5rem;
}

.progress-bar {
  height: 100%;
  transition: width 0.3s ease-in-out; /* Thêm hiệu ứng chuyển động */
}

.home-page__container {
  display: flex;
  flex-direction: row;
  /* background-color: #e9eef8; */
  justify-content: space-between;
  gap: 2.5rem;
  border-radius: 1rem;
  width: 60rem;
}

/* COMMENT */

.status__grade-unit-start {
  background-color: #fed131;
  width: 6.375rem;
  height: 1.5rem;
  border-radius: 0rem 0rem 0.5rem 0.5rem;
  padding: 0.375rem 0.75rem;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  gap: 0.625rem;
}

.status__grade-unit-start p {
  font-size: 0.625rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: normal;
  font-style: normal;
}

/* END */

.status__grade-unit {
  background-color: #0a2a66;
  width: 7.5rem;
  height: 7.7rem;
  border-radius: 0.5rem;
  text-align: center;
  position: absolute;
  z-index: 1;
  position: relative;
  top: -4.5rem;
  left: 1.5rem;
  padding: 0.7rem 0rem;
}

.status-start__grade-unit {
  position: relative;
  top: -4.5rem;
  left: 2.5rem;
}

.status__grade-unit .after-background_grade-unit {
  width: 7.145625rem;
  height: 5.2rem;
  margin: 0 auto;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  background: #fff;
}

.status__grade-unit .title__grade-unit {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.9rem;
  margin-bottom: 0.3rem;
}

.grade-unit__home-page {
  position: relative;
}

.grade-unit__home-page p {
  font-weight: 700;
  font-size: 0.6rem;
  line-height: 2.5em;
  text-transform: uppercase;
  color: #fafbfc;
  text-align: center;
}
.grade-unit__home-page img {
  width: 24.25rem;
  border-radius: 1rem;
  object-fit: cover;
  padding-top: 3.4rem;
}

.grade-unit__group-main {
  display: flex;
  flex-direction: row;
  /* gap: 3.5rem; */
  align-items: center;
  width: 29.25rem;
  height: 15.9375rem;
  background: #ffffff;
  border: 1.5px solid var(--kurio-main-colors-kurio-main-black, #0a2a66);
  border-radius: 1rem;
  padding: 0rem 2rem;
  position: relative;
  box-shadow: 2px 4px 0px 0px #0a2a66;
}

.title-grade-unit__home-page {
  padding: 0.5rem 0.75rem;
  background-color: #E9EEF8;
  border-radius: 1rem;
  display: inline-block;
  height: 2rem;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1rem;
  text-align: left;
  color: #0a2a66;
  max-width: 11.625rem;
}

.title-grade-unit__home-page p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content-document-home-page {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0rem;
  gap: 1rem;
}

.content-document-home-page .number-grade-unit-content {
  font-weight: 700;
  font-size: 3rem;
  line-height: 3.68rem;
  color: #0a2a66;
}

.content-document-home-page .document-grade-unit {
  max-width: 11.625rem;
  font-weight: 700;
  text-align: left;
  font-size: 1.25rem;
}

.documnent-start-lesson {
  width: 11.625rem;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: normal;
}

.progress-button-home-page {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 0rem;
  width: 24.25rem;
}

.document-grade-unit__home-page {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: flex-start;
  width: 11.625rem;
}

.main__content-homepage {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
}

.header-top-popup-ranking .title-ranking-popup .ranking-title {
  display: flex;
}

.hub__ranking-exam {
  position: relative;
  cursor: pointer;
}

.hub__ranking-exam .ranking_one {
  position: absolute;
  font-size: 0.75rem;
  text-align: center;
  color: #fafbfc;
  font-weight: 700;
  top: 13rem;
  left: 9.375rem;
}

.hub__ranking-exam .ranking_two {
  position: absolute;
  font-size: 0.75rem;
  text-align: center;
  color: #fafbfc;
  font-weight: 700;
  top: 15.625rem;
  left: 3.6875rem;
}

.hub__ranking-exam .ranking_three {
  position: absolute;
  font-size: 0.75rem;
  text-align: center;
  color: #fafbfc;
  font-weight: 700;
  top: 17.125rem;
  left: 13.875rem;
}

.hub__exam-solution {
  position: relative;
  width: 38.5rem;
  /* background-image: url("/public/images/background-hub-exam.svg"); */
  background-repeat: no-repeat;
  background-size: cover;
  height: 17.5rem;
  cursor: pointer;
  box-shadow: 2px 4px 0px 0px #0a2a66;
  border-radius: 1rem;
  border: 1.5px solid var(--kurio-main-colors-kurio-main-black, #0a2a66);
}

.hub__exam-solution .date-time-card-monthly-exam {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.75rem;
  gap: 0.625rem;
  position: absolute;
  background-color: #ffffff;
  border-radius: 1rem;
  width: 12.25rem;
  height: 2rem;
  left: 3.25rem;
  top: 9.94rem;
}

.hub__exam-solution .date-time-card-monthly-exam p {
  width: 10.75rem;
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 0.825rem;
  line-height: 1rem;
  text-transform: uppercase;
  color: #0a2a66;
  text-align: center;
}

.hub__exam-solution .content-card-monthly-exam {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 12.5rem;
  margin-left: 3.3rem;
  position: relative;
  z-index: 1;
}

.hub__exam-solution .content-card-monthly-exam .time-content-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  width: 15rem;
  height: 3rem;
  gap: 10px;
  background: #fed131;
  border: 0.25rem solid #0a2a66;
  color: #0a2a66;
  font-weight: 700;
  font-size: 1.1rem;
  border-radius: 1rem;
  z-index: 100;
  position: relative;
  font-family: "Montserrat";
}

.hub__exam-solution .content-card-monthly-exam .time-finished-card {
  position: absolute;
  left: 19.33%;
  right: 66.33%;
  top: 15.2rem;
  bottom: 12.84%;
  background-color: #0a2a66;
  border-radius: 1.5rem;
}

.hub__exam-solution .content-card-monthly-exam .time-finished-card p {
  position: absolute;
  left: 23.17%;
  top: 12.21%;
  font-size: 0.875rem;
  /* line-height: 1rem; */
  text-align: center;
  color: #fafbfc;
  font-weight: 700;
}

.hub__exam-solution .content-card-monthly-exam .button {
  margin-top: -0.6rem;
}

/* Create CSS */

.hub__exam-solution .add-css-content-card-monthly-exam {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 12.5rem;
  margin-left: 3.3rem;
  position: relative;
  z-index: 1;
}

.hub__exam-solution .add-css-content-card-monthly-exam .time-content-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  width: 15rem;
  height: 3rem;
  gap: 10px;
  background: #fed131;
  border: 0.25rem solid #0a2a66;
  color: #0a2a66;
  font-weight: 700;
  font-size: 1.1rem;
  border-radius: 1rem;
  z-index: 100;
  position: relative;
  font-family: "Montserrat";
}

.hub__exam-solution .add-css-content-card-monthly-exam .time-finished-card {
  position: absolute;
  left: 14.33%;
  right: 71.33%;
  top: -1.8rem;
  bottom: 12.84%;
  background-color: #0a2a66;
  border-radius: 1.5rem;
}

.hub__exam-solution .add-css-content-card-monthly-exam .time-finished-card p {
  position: absolute;
  left: 20.17%;
  top: 12.21%;
  font-size: 0.875rem;
  /* line-height: 1rem; */
  text-align: center;
  color: #fafbfc;
  font-weight: 700;
}

.hub__exam-solution .add-css-content-card-monthly-exam .button {
  margin-top: -0.6rem;
}

.hub__exam-solution .add-css-content-card-monthly-exam .add-create-time {
  position: absolute;
  left: 19.33%;
  right: 66.33%;
  top: 15.4rem;
  bottom: 12.84%;
  background-color: #0a2a66;
  border-radius: 1.5rem;
}

.hub__exam-solution .add-css-content-card-monthly-exam .add-create-time p {
  position: absolute;
  left: 23.17%;
  top: 12.21%;
  font-size: 0.875rem;
  /* line-height: 1rem; */
  text-align: center;
  color: #fafbfc;
  font-weight: 700;
}

/* Create CSS */

.hub__exam-solution .content-card-monthly-exam .add-create-time {
  position: absolute;
  left: 13.33%;
  right: 71.33%;
  top: -1.3rem;
  bottom: 12.84%;
  background-color: #0a2a66;
  border-radius: 1.5rem;
}

.hub__exam-solution .content-card-monthly-exam .add-create-time p {
  position: absolute;
  left: 20.17%;
  top: 12.21%;
  font-size: 0.875rem;
  /* line-height: 1rem; */
  text-align: center;
  color: #fafbfc;
  font-weight: 700;
}

.modal-quiz .documnent-quiz {
  text-align: center;
  font-size: 1.1rem;
  color: #0a2a66;
}

.hub__solution {
  position: relative;
  width: 38.5rem;
  height: 9rem;
  /* background-image: url("/public/images/background-hub-solution-exam.svg"); */
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: row;
  gap: 3rem;
  border-radius: 1rem;
  justify-content: center;
  border: 1.5px solid var(--kurio-main-colors-kurio-main-black, #0a2a66);
  background: var(--kurio-main-colors-kurio-main-light-gray, #e9eef8);
  /* block shadow big */
  box-shadow: 2px 4px 0px 0px #0a2a66;
}

.hub__solution img {
  width: 9.375rem;
  height: 6.125rem;
}

.hub__solution .content-card-hub-solution .time-content-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  width: 11rem;
  height: 2.5rem;
  gap: 10px;
  background: #fed131;
  border: 0.25rem solid #0a2a66;
  color: #0a2a66;
  font-weight: 700;
  font-size: 0.875rem;
  border-radius: 1rem;
  z-index: 1;
  position: relative;
  font-family: "Montserrat";
  margin-top: 4.5rem;
  margin-left: 19rem;
}

.hub__solution .content-card-hub-solution .time-finished-card {
  position: absolute;
  left: 67.3%;
  right: 46.33%;
  width: 6rem;
  top: 3.3rem;
  bottom: 41.84%;
  background-color: #0a2a66;
  border-radius: 1.5rem;
}

.hub__solution .content-card-hub-solution .time-finished-card p {
  position: absolute;
  left: 12.17%;
  top: 12.21%;
  font-size: 0.875rem;
  font-size: 0.75rem;
  /* line-height: 1rem; */
  text-align: center;
  color: #fafbfc;
  font-weight: 700;
}

.hub__card-promoted-feature {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1.5rem;
  width: 60rem;
}

.group-promoted {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.75rem 2.5rem;
  gap: 1.5rem;
  width: 19rem;
  height: 7.5rem;
  background: #e9eef8;
  border-radius: 1rem;
}

.group-promoted:nth-child(1):hover {
  cursor: pointer;
  background-color: #572cd1;
}

.group-promoted:nth-child(1):hover p {
  color: #fafbfc;
}

.group-promoted:nth-child(2):hover {
  cursor: pointer;
  background-color: #e2ad25;
}

.group-promoted:nth-child(2):hover p {
  color: #fafbfc;
}

.group-promoted:nth-child(3):hover {
  cursor: pointer;
  background-color: #5b9fdd;
}

.group-promoted:nth-child(3):hover p {
  color: #fafbfc;
}

.group-promoted img {
  width: 4rem;
  height: 4rem;
}

.group-promoted p {
  font-size: 1.175rem;
  color: #0a2a66;
  font-weight: 700;
}

.footer-hub hr {
  border: 1px solid #0a2a66;
  height: 0.0625rem;
}

.footer-hub {
  margin-top: 4rem;
}

/* .content-footer-hub {
  margin-top: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50rem;
} */

.content-footer-hub img {
  width: 15rem;
  height: 4.85rem;
}
.content-footer-hub ul {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
}

.content-footer-hub ul li {
  font-size: 1.1rem;
  font-weight: 500;
  line-height: normal;
  color: #0a2a66;
  cursor: pointer;
}

.content-footer-hub ul li a {
  color: #0a2a66;
}

.footer-hub span {
  font-weight: 700;
  font-size: 0.875rem;
  color: #0a2a66;
}

.hub__show-solution-exam {
  position: relative;
  width: 38.5rem;
  height: 9rem;
  /* background-image: url("/public/images/background-hub-solution-exam.svg"); */
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  gap: 3rem;
  border-radius: 1rem;
  justify-content: center;
  border: 1.5px solid var(--kurio-main-colors-kurio-main-black, #0a2a66);
  background: var(--kurio-main-colors-kurio-main-light-gray, #e9eef8);
  /* block shadow big */
  box-shadow: 2px 4px 0px 0px #0a2a66;
}

.hub__show-solution-exam .content-card-hub-solution {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  margin-left: 12.5rem;
}

.hub__show-solution-exam .content-card-hub-solution .time-content-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  width: 10rem;
  height: 2.5rem;
  gap: 10px;
  background: #fed131;
  border: 0.25rem solid #0a2a66;
  color: #0a2a66;
  font-weight: 700;
  font-size: 0.875rem;
  border-radius: 1rem;
  z-index: 100;
  position: relative;
  font-family: "Montserrat";
  margin-top: 2.3rem;
}

.hub__show-solution-exam .content-card-hub-solution .time-finished-card p {
  position: absolute;
  left: 8.17%;
  top: 13.21%;
  font-size: 0.875rem;
  font-size: 0.75rem;
  /* line-height: 1rem; */
  text-align: center;
  color: #fafbfc;
  font-weight: 700;
}

.hub__show-solution-exam .time-finished-card-show-solution {
  position: absolute;
  left: 41.3%;
  right: 12.33%;
  width: 8.25rem;
  top: 2rem;
  bottom: 46.84%;
  background-color: #0a2a66;
  border-radius: 1.5rem;
}

.hub__show-solution-exam
  .content-card-hub-solution
  .time-content-card-show-solution {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  width: 10rem;
  margin: 0 auto;
  height: 2.5rem;
  gap: 10px;
  background: #d6cdf2;
  border: 0.25rem solid #0a2a66;
  border-radius: 1rem;
  font-family: "Montserrat";
  position: relative;
}

.hub__show-solution-exam
  .content-card-hub-solution
  .time-content-card-show-solution
  span {
  color: #572cd1;
  font-weight: 700;
  font-size: 1.2rem;
  text-align: center;
}
