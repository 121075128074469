.MainContent9Context0 span math {
  font-size: 1.6rem !important;
  font-weight: 700 !important;
}
.MainContent9Context1 span math {
  font-size: 1.1rem !important;
  font-weight: 700 !important;
}
.MainContent10Context span math {
  font-size: 1.6rem !important;
  font-weight: 700 !important;
}
.MainContent11Context span math {
  font-size: 1.25rem !important;
  font-weight: 500 !important;
}
.MainContentTitle span math {
  font-size: 2.1rem !important;
  font-weight: 700 !important;
}