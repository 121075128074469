
/* keyboard keys */
.rule-numberle::-webkit-scrollbar {
  width: 0.25rem;
  height: 2rem;
}
/* Handle */
.rule-numberle::-webkit-scrollbar-thumb {
  background: #0a2a66;
  /* height: 5rem; */
  border-radius: 1rem;
}
.rule-numberle::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
}
.keyStyle {
  font-size: 1.5rem;
  min-width: 2rem;
  height: 4rem;
  width: 3.5rem;
  border-radius:5px;
}
.numberle {
  gap: 1.25rem;
}

.keyStyle:hover {
  border: 1px solid #D3D6DA;
  background: #D3D6DA;
}

/* input box  */

.inputBox {
  margin-top: 20px;
  width: 150px;
  height: 24px;
  border: 1px solid white;
  border-radius: 5px;
  font-size: xx-large;
  background-color: lightblue;
  padding: 8px;
}
.tile-numberle {
  width: 4rem;
  height: 4rem;
  font-size: 2rem
}
.keyboard {
  gap: 1.5rem;
}
.keyboard-number {
  gap: 1rem;
}
.btn-numberle {
  padding: 0rem 1rem 0rem 1rem;
  font-size: 1rem;
  line-height: normal;
  gap: 0.75rem;
}
.btn-mode {
  padding: 0.75rem 0.5rem;
  font-size: 0.875rem;
  line-height: normal;
  gap: 0.75rem;
}
.group-btn-numberle{
  gap: 1rem;
}
.group-btn-mode {
  gap: 1rem;
}
.close-modal-numberle {
  top: -2.5rem;
  left: -2.5rem;
  z-index: 999;
}
@media (max-width:560px){
  .close-modal-numberle {
    top: -1.5rem;
    left: -1.5rem;
  }
  .close-modal-numberle-btn {
    width: 3.5rem  !important;
    height: 4.125rem !important;
  }
  .close-modal-numberle-btn .elip-button {
    width: 3.5rem !important;
    height: 3.5rem !important;
  }
}

@media (max-width:450px){
  .group-btn-numberle {
    gap: 0.75rem;
  }
  .numberle-message {
    font-size: 0.9375rem;
  }
  .group-btn-mode {
    gap: 0.5rem;
  }
  .keyStyle {
    font-size: 1.25rem;
    min-width: 2.5rem;
    height: 2.5rem;
  }
  .btn-numberle {
    font-size: 0.875rem;
    padding: 0rem 0.5rem 0rem 0.5rem;
    gap: 0.5rem;
  }
  .btn-mode {
    font-size: 0.65rem;
    padding: 0.25rem 0.25rem;
    gap: 0.5rem;
  }
  .numberle {
    gap: 0.75rem;
  }
  .keyboard {
    gap:0.5rem 0.25rem;
  }
  .keyboard-number {
    gap: 0.5rem;
  }
  .tile-numberle {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1rem
  }
  .tile-modal .tile-numberle {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 0.875rem
  }
  /* .keyStyle {
    min-width: 1rem;
    height: 1.5rem;
    border-radius:5px;
    padding: 5px;
    font-size: 0.5rem;
  } */
}

@media (max-width:390px){
  .close-modal-numberle {
    top: -1.25rem;
    left: -1.25rem;
  }
  .close-modal-numberle-btn {
    width: 3rem  !important;
    height: 3.625rem !important;
  }
  .close-modal-numberle-btn .elip-button {
    width: 3rem !important;
    height: 3rem !important;
  }
}