.logo-kurio {
  display: flex;
  width: 11.125rem;
  height: 4rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 1rem;
  border: 1.5px solid var(--kurio-main-colors-kurio-main-black, #0a2a66);
  background: #fff;
  /* block shadow big */
  box-shadow: 2px 4px 0px 0px #0a2a66;
}

.menu-nav-bar-left {
  display: flex;
  width: 11.125rem;
  gap: 0.75rem;
  padding: 1rem;
  flex-direction: column;
  align-items: center;
  border-radius: 1rem;
  border: 1.5px solid var(--kurio-main-colors-kurio-main-black, #0a2a66);
  background: var(--kurio-main-colors-kurio-main-light-gray, #e9eef8);
  /* block shadow big */
  box-shadow: 2px 4px 0px 0px #0a2a66;
}