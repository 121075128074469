.main-content-select::-webkit-scrollbar {
  width: 0.5rem;
  height: 5rem;
} 
/* Handle */
.main-content-select::-webkit-scrollbar-thumb {
  background: #909090; 
  /* height: 5rem; */
  border-radius: 1rem;
}
.main-content-select::-webkit-scrollbar-thumb:hover{
  cursor: pointer;
}
.filter-question-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5rem 5rem;
  /* gap: 1.5rem; */
  width: 53.75rem;
}
