.practice-content::-webkit-scrollbar {
  width: 10.5rem;
  height: 26.25rem;
} 
/* Handle */
.practice-content::-webkit-scrollbar-thumb {
  background: #0A2A66; 
  height: 36.25rem;
  border: 5rem solid transparent;
  /* border-bottom: 5rem solid #E6E1F5; */
  /* border-top: 5rem solid #E6E1F5; */
  background-clip: padding-box;
  /* z-index: 999; */
  border-radius: 10rem;
}

.practice-content::-webkit-scrollbar-thumb:hover{
  cursor: pointer;

}