.quiz__unit {
    /* background-image: url(/public/images/BackgroundLesson.png); */
    background-repeat: no-repeat;
    background-size: cover;
    width: 42rem;
    height: 7.5rem;
    object-fit: cover;
    border-radius: 1rem;
    position: relative;
    cursor: pointer;
    display: flex;
    gap: 2.5rem;
    align-items: center;
    padding: 1.31rem 2.22rem;
    border-radius: 1rem;
    border: 1.5px solid var(--kurio-main-colors-kurio-main-black, #0A2A66);
    /* block shadow big */
    box-shadow: 2px 4px 0px 0px #0A2A66;
}

.quiz__content-success p {
    color: #572CD1;
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.43rem;
}

.quiz__content-success span {
    font-size: 1.1rem;
    line-height: 1.375rem;
    font-weight: 500;
    color: #572CD1;
}

.quiz__content-disbled p {
    color: #8C9DC1;
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.43rem;
}

.quiz__content-disbled span {
    font-size: 1.1rem;
    line-height: 1.375rem;
    font-weight: 500;
    color: #8C9DC1;
}

.quiz__content p {
    color: #fff;
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.43rem;
}

.quiz__content span {
    font-size: 1.1rem;
    line-height: 1.375rem;
    font-weight: 400;
    color: #fff;
}

.quiz__test-score {
    position: absolute;
    background-image: url("/public/images/button/exam-score.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 7.5rem;
    height: 7.5rem;
    text-align: center;
    right: -2.2rem;
    top: 1.2rem;
}

.quiz__test-score p {
    color: #572CD1;
    font-weight: 700;
    font-size: 3rem;
    line-height: 3.68rem;
}

.quiz__test-score span {
    color: #572CD1;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.81rem;
    margin-top: -0.6rem;
}

.quiz__test-score-success {
    position: absolute;
    background-image: url("/public/images/button/exam-score-success.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 7.5rem;
    height: 7.5rem;
    text-align: center;
    right: -2.2rem;
    top: 1.2rem;
}

.quiz__test-score-success p {
    color: #572CD1;
    font-weight: 700;
    font-size: 3rem;
    line-height: 3.68rem;
}

.quiz__test-score-success span {
    color: #572CD1;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.81rem;
    margin-top: -0.6rem;
}

.modal-quiz {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2.5rem;
    gap: 2rem;
    width: 36rem;
    background-color: #FAFBFC;
    border-radius: 2rem;
}

.modal-quiz span {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.3rem;
    color: #0A2A66;
    text-align: center;
}

.title-popup-quiz {
    font-weight: 700;
    font-size: 2.25rem;
    line-height: 2.75rem;
    color: #572CD1;
    text-align: center;
}

.sub-title-popup {
    font-size: 1rem;
    line-height: 1.3rem;
    text-align: center;
    color: #0A2A66;
    font-weight: 500;
}

.document-quiz {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.375rem;
    text-align: center;
    color: #0A2A66;
}

.lesson-title span math {
    font-size: 1.25rem !important;
}

.lesson-start-up-title {
    font-size: 2.25rem !important;
}

.unit-name span math {
    font-size: 1.6rem !important;
    font-weight: 700 !important;
    line-height: normal;
}