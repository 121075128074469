.game-board {
  display: grid;
  gap: 5px;
  margin: auto;
  background-color: #cccccc;
  padding: 5px;
  user-select: none;
}

@media (min-width: 325px) and (max-width: 460px) {
  .grid {
    width: 35px !important;
    height: 35px !important;
  }
}

.grid {
  position: relative;
  width: 45px; /* adjust size as needed */
  height: 45px;
  background-color: lightgray;
  border: 1px solid white;
  justify-content: center;
  align-items: center;
  display: flex;
  user-select: none;
}

.connected {
  background-color: transparent;
}
