.btn-button:hover {
  background-color: #fafbfc;
  border: 1.5px solid #0A2A66;
  /* box-shadow: 0rem 0.375rem 1rem rgba(10, 42, 102, 0.25); */
}

.btn-button-IKMC {
  padding: 1rem 1.5rem;
  width: 25rem;
  background: #e9eef8;
  cursor: pointer;
  border-radius: 0.5rem;
  border: 1.5px solid #0A2A66;
}

input[type="checkbox"]:checked::before {
  content: "";
  background-color: red;
  width: 16px;
  height: 16px;
}

input[type="checkbox"]:checked::after {
  content: "";
  font-size: 20px;
  margin-left: 0.2rem;
  color: #0A2A66;
  line-height: 1;
  text-align: center;

}