.react-pdf__Page{
  position: static !important;
}
.react-pdf__Page canvas {
  position: relative;
  z-index: 999;
  width: 40vw ;
  max-width:  100% ;
  height: 56.57vw ;
  border-radius: 0rem ;
  max-height: 90vh;
}
.preview-loading {
  width: 40vw;
  height: 56.57vw;
  max-height: 90vh;
}
.react-pdf__Page__textContent{
  width: 40vw ;
  max-width:  100% ;
  /* height: 56.57vw ; */
  max-height: 90vh;
}
.react-pdf__Document{
  /* height: 56.57vw; */
  max-height: 90vh;
}
.list-games {
  align-items: flex-start;
  align-content: flex-start;
  width: 52.5rem;
  padding: 1.5rem 0rem 1.5rem 0rem;
}
.game-header-desktop {
  display: flex;
}
.game-header-mobile {
  display: none;
}
/* @media (min-width: 1050px) {
  .react-pdf__Page canvas {
    width: 35vw !important;
    height: 49.5vw !important;
    max-height: 90vh;
  }
  .preview-loading {
    width: 35vw;
    height: 49.5vw !important;
  }
  .react-pdf__Page__textContent{
    max-width:  100% !important;
    height: 49.5vw !important;
    max-height: 90vh;
  }
  .react-pdf__Document{
    max-height: 49.5vw;
  }
} */
@media (max-width: 840px) {
  /* .react-pdf__Page canvas {
    width: 80vw !important;
    height: 113.143vw !important;
  }
  .preview-loading {
    width: 80vw;
    height: 113.143vw !important;
  }
  .react-pdf__Page__textContent{
    max-width:  100% !important;
    height: 113.143vw !important;
  }
  .react-pdf__Document{
    max-height: 113.143vw;
  } */
  .desktop-image {
    display: none !important;
  }
  .desktop-banner {
    display: none !important;
  }
  .mobile-image {
    display: block !important; 
  }
  .game-header-desktop {
    display: none !important;
  }
  .game-container { 
    padding: 0rem !important;
  }
  .mobile-banner {
    display: flex !important;
    width: 100vw;
  }
  .game-header-mobile {
    display: flex !important;
    position: fixed;
    bottom: 0rem;
    z-index: 1;
  }
  .list-games {
    /* flex-direction: column; */
    align-items: center;
    align-content: center;
    width: 100vw;
    padding: 1.5rem 0rem 7.5rem 0rem;
  }

}
/* @media (min-width: 550px) and (max-width: 700px) {
  .react-pdf__Page canvas {
    width: 50vw !important;
    height: 70.714vw !important;
  }
  .preview-loading {
    width: 50vw;
    height: 70.714vw !important;
  }
  .react-pdf__Page__textContent{
    width: 50vw;
    max-width:  100% !important;
    height: 70.714vw !important;
  }
  .react-pdf__Document{
    height: 70.714vw;
    max-height: 90vh;
  }
} */
@media (max-width: 700px) {
  .react-pdf__Page canvas {
    width: 90vw ;
    height: 127.3vw ;
  }
  .preview-loading {
    width: 90vw;
    height: 127.3vw ;
  }
  .react-pdf__Page__textContent{
    width: 90vw;
    max-width:  100% ;
    /* height: 127.3vw ; */
  }
  .react-pdf__Document{
    /* height: 127.3vw; */
    max-height: 90vh;
  }
}
/* @media (min-height: 900px) and (max-height: 999px) {
  .page-controls {
    bottom: -4vh !important
  }
  .react-pdf__Page canvas {
    margin-top: -2.5vh;
  }
}
@media (min-height: 1000px){
  .page-controls {
    bottom: -6.5vh !important
  }
  .react-pdf__Page canvas {
    margin-top: -6.5vh;
  }
} */