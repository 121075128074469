.challenge-menu {
  position: fixed;
  left: -100%;
  top: 25%;
  background-color: #f1f1f1;
  transition: left 0.3s ease-in-out;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.challenge-menu.open {
  left: 0;
}

.challenge-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 cột */
  gap: 1rem; /* Khoảng cách giữa các nút */
  justify-items: center; /* Căn giữa các phần tử trong mỗi ô */
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
}

.challenge-button {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 8px;
  background: linear-gradient(135deg, #acefef, #56d6d6);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.challenge-button p {
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #333;
  margin: 0;
}

.challenge-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.correct-message {
  color: green;
  animation: fadeIn 0.5s ease-in-out;
}

.incorrect-message {
  color: red;
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.selected-color {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.box-color {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
}

@media (min-width: 325px) and (max-width: 500px) {
  .selected-color {
    display: flex;
    flex-direction: column !important;
  }

  .box-color {
    justify-content: center;
  }
}