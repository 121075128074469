.dropdown-item {
  color: #0A2A66 !important;
  width: 9rem;
  height: 2.75rem;
  border-radius: 1.5rem;
}
/* .dropdown-item:hover {
  background: #0A2A66;
  color: #FAFBFC !important;
} */
.dropdown-item p {
  line-height: normal;
}
